// import { getCommonParamsItemLabel } from '@/utils/common.js'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'code',
      label: 'profile.code',
      width: '320'
    },
    {
      prop: 'name',
      label: 'general.name',
      width: '320'
    },

    // {
    //   prop: 'status',
    //   label: 'general.status',
    //   formatter: (row) => {
    //     return getCommonParamsItemLabel(row, 'status')
    //   }
    // },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
      // slotName: 'formateTime'
    },
    {
      label: 'common.app-operation',
      width: '400',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
