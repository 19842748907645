// import { getCommonParamsOptions } from '@/utils/common.js'

export const searchFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'code',
      type: 'input',
      label: 'profile.code'
    },
    {
      field: 'name',
      type: 'input',
      label: 'general.name'
    },
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'general.status',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('status')
    // },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
