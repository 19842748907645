<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-data-dictionary')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          class="opt-btn"
          icon="el-icon-view"
          size="mini"
          type="success"
          plain
          @click="handleViewData(scope.row)"
          >{{ $t('general.detail') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'

import { useRouter } from 'vue-router'

// 表格基础配置项
const tableOptions = {
  moduleName: 'system', // 所属的模块
  pageName: 'dict', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowViewBtn: false,
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  pageAction: 'system/getDataDictionaryList', // 获取表格的Action
  deleteRecordAction: 'system/deleteDataDictionaryById', // 删除record的Action
  pageCountGetter: 'system/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'system/pageListData' // 获取表格列表数据getters,
}

const pageSearchRef = ref('')

const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (row) => {
  router.push(`/system/dict/detail/${row.name}/${row.id}`)
}

const handleEditData = (id) => {
  router.push(`/system/dict/edit/${id}`)
}

const handleNewData = (id) => {
  router.push(`/system/dict/new`)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
